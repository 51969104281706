import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTableView from '../../../components/data-table/data-table-view';
import Moment from 'react-moment';
import moment from 'moment/moment';
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import MonthYearPicker from 'react-month-year-picker';
import update from 'immutability-helper';
import ProjectionController from '../../../api/controller/projection-controller';
import { ButtonWithLoader, toastify } from '@devopsthink/react-custom-component-util';

function PmMtProjectRevenueProjection({
                                        project,
                                        userInfo,
                                        updateProjectionModal,
                                        toggleUpdateProjectionModal,
                                        findProjectById,
                                        salesProjectAdmin,
                                      }) {

  const currYear = new Date().getFullYear();
  const { t } = useTranslation();
  const [isLoaded, set_isLoaded] = useState(false);
  const [isUpLoaded, set_isUpLoaded] = useState(false);
  const [isUpdateBtnLoading, set_isUpdateBtnLoading] = useState(false);
  const [projectionUpdate, set_projectionUpdate] = useState({
    projectionAmount: null,
    projectionYear: currYear,
    projectionMonth: new Date().getMonth() + 1,
  });

  function editServiceDetails(rowData) {
    set_projectionUpdate(rowData);
    toggleUpdateProjectionModal();

  }

  function onProjectionUpdate(e) {
    set_projectionUpdate(projection_prev => update(projection_prev, { 'projectionAmount': { $set: e.target.value } }));
  }

  async function updateProjection() {
    set_isUpdateBtnLoading(true);

    if (userInfo.role.internalName === 'PM') {
      projectionUpdate.projectAdmin = { paId: project.projectAdmin.paId };
    } else {
      projectionUpdate.projectAdmin = { paId: salesProjectAdmin.paId };
    }

    await ProjectionController.updateProjection(projectionUpdate).then(result => {
      toastify('success', t('program.manager.project.actual.rev.updated.projection'), t('program.manager.project.actual.rev.updated.projection'));
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      findProjectById(project.projectId);
      toggleUpdateProjectionModal();
      set_isUpdateBtnLoading(false);
    });
  }


  const columns = useMemo(() => [{
    name: 'Year',
    sortable: true,
    selector: rowData => rowData.projectionYear,
    filter: (rowData, value) => rowData.projectionYear.toString().toLowerCase().includes(value.toLowerCase()),

  },

    {
      name: 'Month',
      sortable: true,
      selector: rowData => rowData.projectionMonth,
      filter: (rowData, value) => moment().set('month', rowData.projectionMonth - 1).format('MMMM').toLowerCase().includes(value.toLowerCase()),
      cell: (rowData) => <Moment
        format="MMMM">{moment().set('month', rowData.projectionMonth - 1)}</Moment>,

    }, {
      name: 'Invoiced Amount',
      sortable: true,
      selector: row => row.projectionAmount ? row.projectionAmount.toLocaleString('en-IN') : 0,
      filter: (rowData, value) => rowData.projectionAmount.toLocaleString().includes(value),
    },
    {
      name: '', cell: (rowData) =>
        <span>
          {userInfo.role.internalName !== 'MT' ? (<span>
                <Button className="next_outline_accent1_btn next_btn_lg"
                        disabled={project.projectStatus === 'N'}
                        onClick={() => editServiceDetails(rowData)}> {t('common.component.project.service.details.btn.edit')}

                </Button>
            </span>) : (<span></span>)}
            </span>,
    },
  ]);


  useEffect(() => {
    set_isLoaded(false);

    async function fetchData() {
      set_isLoaded(true);
    }

    fetchData();

  }, []);


  return (<div>
    <div className="feedback_table containt_e2e-datatable mt-3">
      <DataTableView
        title={t('pm.close.projects.title')}
        columns={columns}
        data={project.projections}
        options={{ search: true, extendedSearch: false, pagination: true }}
      />
    </div>

    {/*=====================================Update Est Revenue=====================================*/}

    <Modal
      isOpen={updateProjectionModal}
      toggle={toggleUpdateProjectionModal}
      //className={this.props.className}
      backdrop="static"
    >
      <ModalHeader
        toggle={toggleUpdateProjectionModal}>{t('program.manager.project.actual.rev.modal.header.update.projection')} </ModalHeader>
      <ModalBody>
        {/*<div className="fs-5">Select month and year</div>*/}
        <FormGroup className={'integr8_month-year-picker'}>

          <MonthYearPicker
            selectedMonth={projectionUpdate.projectionMonth}
            selectedYear={projectionUpdate.projectionYear}
            minYear={2020}
            maxYear={2030}
            onChangeYear={year => {
              set_projectionUpdate(projection_prev => {
                return { ...projection_prev, projectionYear: year };
              });
            }}
            onChangeMonth={month => {
              set_projectionUpdate(projection_prev => {
                return { ...projection_prev, projectionMonth: month };
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={2} className={'pe-0'}><label
              className="mt-2">{t('program.manager.project.actual.rev.amount')}</label></Col>
            <Col md={9} className={'ps-0'}>
              <Input type="number" name="projectionAmount"
                     onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                     min={1}
                     onChange={onProjectionUpdate}
                     defaultValue={projectionUpdate.projectionAmount}
                     placeholder={t('pm.new.projection.modal.placeholder.text')}
                     className="mb-2 next_input" />
            </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button className="next_cancel_btn"
                onClick={toggleUpdateProjectionModal}
        >
          {t('integr8.common.cancel.text')}
        </Button>

        <ButtonWithLoader className="next_btn ms-2"
                          loading={isUpdateBtnLoading}
          // disabled={_.isEmpty(projection.projectionAmount) || (projection.projectionAmount).length === 0}
                          onClick={updateProjection}
                          text={t('program.manager.project.actual.rev.modal.update.btn')}
        >
        </ButtonWithLoader>

      </ModalFooter>
    </Modal>

  </div>);

}

export default PmMtProjectRevenueProjection;